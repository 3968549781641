import { z } from "zod";

export const emailSchema = z.string().email().max(64, "Email too long");

export const passwordRequirements = [
  { re: /[0-9]/, label: "Includes number" },
  { re: /[a-z]/, label: "Includes lowercase letter" },
  { re: /[A-Z]/, label: "Includes uppercase letter" },
  { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: "Includes special symbol" },
];

export const passwordSchema = passwordRequirements.reduce(
  (schema, req) => {
    const label = req.label.replace("Includes", "Password must include");
    return schema.regex(req.re, label);
  },
  z
    .string()
    .min(6, "Password must be at least 6 characters")
    .max(32, "Password must be less than 32 characters"),
);

export const passwordMaxSchema = z
  .string()
  .max(32, "Password must be less than 32 characters");

export const userNameSchema = z.string().max(64, "Name too long");

export const companyNameSchema = z.string().max(64, "Name too long");

export const roleNameSchema = z.string().max(64, "Name too long");

export const passwordResetTokenSchema = z
  .string()
  .max(30, "Reset token too long");

export const tokenSchema = z.string().max(1440, "Auth token too long");
