import { VariantProps, cva } from "class-variance-authority";
import { ComponentProps, forwardRef } from "react";
import { twMerge } from "tailwind-merge";
import Link from "next/link";

const buttonStyles = cva(
  "font-lato w-fit font-bold text-base leading-4 flex items-center justify-center gap-2",
  {
    variants: {
      variant: { primary: "", secondary: "", tertiary: "" },
      color: { default: "", destructive: "" },
      size: {
        big: "px-7 h-14 rounded-2xl",
        default: "px-6 h-12 rounded-xl",
        medium: "px-5 h-10 rounded-xl",
        small: "px-[14px] h-8 rounded-xl text-sm leading-3.5",
      },
      hasIcon: { true: "", false: "" },
    },
    defaultVariants: { variant: "primary", color: "default", size: "default" },
    compoundVariants: [
      // Primary
      {
        variant: "primary",
        color: "default",
        className: [
          "text-txt-inverted disabled:text-txt-inactive",
          "bg-btn-primary hover:bg-btn-primary-hover active:bg-btn-primary-pressed disabled:bg-btn-primary-disabled",
        ],
      },
      {
        variant: "primary",
        color: "destructive",
        className: [
          "text-txt-inverted disabled:text-txt-inactive",
          "bg-btn-primary-destructive hover:bg-btn-primary-destructive-hover active:bg-btn-primary-destructive-pressed disabled:bg-btn-primary-disabled",
        ],
      },
      // secondary
      {
        variant: "secondary",
        color: "default",
        className: [
          "text-txt-heading disabled:text-txt-inactive",
          "border border-stroke-dark bg-btn-secondary hover:bg-btn-secondary-hover active:bg-btn-secondary-pressed disabled:border-btn-secondary-disabled disabled:bg-transparent",
        ],
      },
      {
        variant: "secondary",
        color: "destructive",
        className: [
          "text-v2-status-error-dark disabled:text-txt-inactive",
          "border border-stroke-error bg-btn-secondary-destructive hover:bg-btn-secondary-destructive-hover active:bg-btn-secondary-destructive-pressed disabled:border-btn-secondary-disabled disabled:bg-transparent",
        ],
      },
      // tertiary
      {
        variant: "tertiary",
        color: "default",
        className: [
          "text-txt-heading disabled:text-txt-inactive",
          "border border-stroke-grey ",
          "bg-btn-tertiary hover:bg-btn-tertiary-hover active:bg-btn-tertiary-pressed disabled:border-btn-secondary-disabled disabled:bg-transparent",
        ],
      },
      {
        variant: "tertiary",
        color: "destructive",
        className: [
          "text-v2-status-error-dark disabled:text-txt-inactive",
          "border border-stroke-error-2 disabled:border-stroke-grey",
          "bg-btn-tertiary-destructive hover:bg-btn-tertiary-destructive-hover active:bg-btn-tertiary-destructive-pressed disabled:bg-btn-tertiary-disabled disabled:bg-transparent",
        ],
      },
      // Icon
      { hasIcon: true, size: "big", className: "px-5" },
      { hasIcon: true, size: "default", className: "px-4" },
      { hasIcon: true, size: "medium", className: "px-3" },
      { hasIcon: true, size: "small", className: "px-3" },
    ],
  },
);

const BaseButton = forwardRef(
  <As extends "button" | "a" = "button">(
    {
      children,
      className,
      as: asElement,
      leftSlot: LeftSlot,
      rightSlot: RightSlot,
      ...props
    }: Omit<VariantProps<typeof buttonStyles>, "hasIcon"> &
      ComponentProps<As> & {
        children: React.ReactNode | string;
        as?: As;
        disabled?: boolean;
        className?: string;
        leftSlot?: (props: { size: number }) => React.ReactNode;
        rightSlot?: (props: { size: number }) => React.ReactNode;
      },
    ref: React.Ref<HTMLButtonElement | HTMLAnchorElement>,
  ) => {
    const Element = asElement === "a" ? Link : "button";
    const iconSize = props.size === "big" ? 24 : props.size === "default" ? 20 : 16;
    return (
      <Element
        /* @ts-expect-error:  */
        ref={ref}
        className={twMerge(
          buttonStyles({ ...props, hasIcon: Boolean(LeftSlot || RightSlot) }),
          className,
        )}
        {...props}
        color={undefined}
      >
        {LeftSlot && <LeftSlot size={iconSize} />}
        {children}
        {RightSlot && <RightSlot size={iconSize} />}
      </Element>
    );
  },
);

BaseButton.displayName = "BaseButton";

export default BaseButton;
