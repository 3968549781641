import { useForm, zodResolver } from "@mantine/form";
import { z } from "zod";

const getDefaults = <Schema extends z.AnyZodObject>(schema: Schema) => {
  return Object.fromEntries(
    Object.entries(schema.shape).map(([key, value]) => {
      if (value instanceof z.ZodDefault)
        return [key, value._def.defaultValue()];
      return [key, undefined];
    }),
  );
};

export const useZodForm = <
  Schema extends z.ZodObject<Record<string, z.ZodTypeAny>>,
>(
  schema: Schema,
) => {
  const form = useForm<z.infer<Schema>>({
    initialValues: {
      ...getDefaults(schema),
    },
    validate: zodResolver(schema),
  });

  return form;
};
