import { cva } from "class-variance-authority";
import { InfoIcon } from "lucide-react";
import { twMerge } from "tailwind-merge";

const descriptionStyles = cva("text-txt-inactive text-xs font-medium font-lato", {
  variants: {
    error: {
      true: "text-txt-error",
      false: "",
    },
  },
});

const inputStyles = cva(
  "rounded-1.5xl border-stroke-grey-2 bg-background-grey-2 focus:border-stroke-dark focus:ring-stroke-dark !text-txt-heading mt-1.5",
  { variants: {} },
);

const wrapperStyles = cva("text-txt-inactive", {
  variants: {
    variants: {},
    disabled: { true: "", false: "" },
  },
});

const rightSectionStyles = cva("h-fit", {
  variants: {
    onClick: {
      true: "cursor-pointer",
      false: "",
    },
  },
});

export const inputSharedStyles = {
  description: (...args: Parameters<typeof descriptionStyles>) =>
    twMerge(descriptionStyles(...args)),
  input: (...args: Parameters<typeof inputStyles>) => twMerge(inputStyles(...args)),
  wrapper: (...args: Parameters<typeof wrapperStyles>) => twMerge(wrapperStyles(...args)),
  rightSection: (...args: Parameters<typeof rightSectionStyles>) =>
    twMerge(rightSectionStyles(...args)),
};

export const InputLabel = (props: {
  label: string;
  disabled?: boolean;
  info?: string;
  error?: boolean;
  required?: boolean;
}) => {
  return (
    <div className="flex gap-2 items-center">
      <p
        className={twMerge(
          "text-txt-heading text-sm font-medium",
          props.disabled && "text-txt-inactive",
        )}
      >
        {props.label}
      </p>
      {props.info && (
        <InfoIcon
          size={16}
          className={twMerge("text-txt-inactive", props.error && "text-txt-error")}
        />
      )}
    </div>
  );
};
