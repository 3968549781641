/* eslint-disable react/display-name */
import type {
  CSSObject,
  MantineTheme,
  PasswordInputProps,
  PasswordInputStylesNames,
  SelectProps,
  SelectStylesNames,
  TextInputProps,
  TextInputStylesNames,
} from "@mantine/core";
import {
  TextInput as MantineTextInput,
  PasswordInput as MantinePasswordInput,
  Select as MantineSelect,
} from "@mantine/core";
import { Ref } from "react";
import { forwardRef } from "react";
import { VscEye, VscEyeClosed } from "react-icons/vsc";

type InputStyles =
  | Partial<Record<TextInputStylesNames | PasswordInputStylesNames | SelectStylesNames, CSSObject>>
  | ((
      theme: MantineTheme,
    ) => Partial<
      Record<TextInputStylesNames | PasswordInputStylesNames | SelectStylesNames, CSSObject>
    >);

const inputStyles: InputStyles = {
  required: {
    display: "none",
  },
  label: {
    fontSize: "14px",
    color: "#151414",
    fontFamily: "Lato",
  },
  input: {
    backgroundColor: "#F6F4F4",
  },
};

export const TextInput = forwardRef(
  (props: TextInputProps, ref: Ref<HTMLInputElement> | undefined) => {
    return (
      <MantineTextInput
        ref={ref}
        styles={{ ...inputStyles, ...(props?.styles ?? {}) }}
        {...props}
      />
    );
  },
);

export const PasswordInput = (props: PasswordInputProps) => {
  return (
    <MantinePasswordInput
      minLength={6}
      styles={{ ...inputStyles, ...(props?.styles ?? {}) }}
      visibilityToggleIcon={({ reveal, size }) => {
        if (reveal) {
          return <VscEyeClosed size={size} />;
        }

        return <VscEye size={size} />;
      }}
      {...props}
    />
  );
};

export const Select = (props: SelectProps) => {
  return <MantineSelect styles={{ ...inputStyles, ...(props?.styles ?? {}) }} {...props} />;
};
